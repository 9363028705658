<template>
    <div class="hero flex flex-col-reverse lg:flex-row pt-20 xl:pt-24"> 
        <div class="w-full lg:w-1/2 flex justify-center lg:justify-end container mx-auto">
            <div class="lg:max-w-2xl pl-6 pr-6 lg:pl-8 xl:pr-20 pt-2 lg:pt-12 pb-12 lg:pb-24 ">
                <div class="leader mb-8">
                    <h1 class="text-2xl xl:text-3xl font-condensed leading-tight text-gray-800">
                        The digital agency that's asking the <span class="font-semibold">right questions</span> and
                        <span class="text-gray-900 text-2xl xl:text-3xl font-heading font-light">creating the <span class="font-semibold text-red-600">answer</span></span>
                    </h1>
                </div>
                
                <p class="font-body leading-relaxed mb-4 text-gray-700">At iNQUiSiTiON we understand that first impressions are important. You are your business and that's why we want to get to know you.</p>

                <p class="font-body font-light text-gray-700 leading-relaxed">Whether you're at the start of your journey and looking to develop your brand, or an established powerhouse wanting to reach greater heights, our <router-link to="/design" class="underline">design</router-link>, <router-link to="/branding" class="underline">branding</router-link> and <router-link to="/printing" class="underline">printing</router-link> experience can help you <span class="font-semibold">stand above the rest</span>.</p>
            </div>
        </div>
        <div class="hero-image w-full lg:w-1/2 flex flex-col lg:pl-12 relative">
            <svg 
                preserveAspectRatio="none" 
                viewBox="0 0 70 70" 
                class="hero-background transform rotate-180 fill-gray-light lg:hidden"
            >
                <polygon points="70,0 0, 70 70,70" opacity="1"></polygon>
            </svg>

            <svg 
                preserveAspectRatio="none" 
                viewBox="0 0 70 70" 
                class="absolute bottom-0 transform rotate-180 hero-background hero-background-desktop fill-gray-light hidden lg:block self-end item-end"
            >
                <polygon points="0,70 70, 0 0,0" opacity="1"></polygon>
            </svg>

            <agile 
                :autoplay="true"
                :fade="true"
                :navButtons="false"
                :speed="1750"
                class="pt-6 flex"
            >
                <div class="slide">
                    <img src="@/assets/img/slider/slider1.png" class="lg:max-w-lg">
                </div>
                <div class="slide">
                    <img src="@/assets/img/slider/slider2.png" class="lg:max-w-lg lg:-mt-6">
                </div>
                <div class="slide">
                    <img src="@/assets/img/slider/slider3.png" class="lg:max-w-lg">
                </div>
                <div class="slide">
                    <img src="@/assets/img/slider/slider4.png" class="lg:max-w-lg">
                </div>
                <div class="slide">
                    <img src="@/assets/img/slider/slider5.png" class="lg:max-w-lg">
                </div>
            </agile>
        </div>
    </div>
</template>

<script>
    import { VueAgile } from 'vue-agile'

    export default {
        name: 'Hero',
        components: {
            agile: VueAgile,
        },
    }
</script>

<style scoped>
    .hero-background {
        @apply absolute;
        z-index: -5;
    }

    .slide {
        @apply flex justify-center;
    }
    
    .slide img {
        max-height: 12.5rem;
    }

    @media (min-width: 640px) { 
        .slide img {
            max-height: 20rem;
        }
    }

    @media (min-width: 1280px) { 
        .slide {
            @apply justify-start;
        }
    
        .slide img {
            max-height: 25rem;
        }

        .hero-background-desktop {
            width: 65rem;
        }
    }
    @media (min-width: 2000px) { 
        .hero-background-desktop {
            width: 75rem;
        }
    }
    @media (min-width: 2200px) { 
        .hero-background-desktop {
            width: 90rem;
        }
    }
</style>