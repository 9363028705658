<template>
    <router-link :to="service.path">
        <div class="service p-6 sm:m-2 mb-2 sm:mb-4 w-60 h-36 lg:h-48 transition duration-500 ease-in-out shadow-md" :class="style">
            <div class="service-info">
                <h3 class="font-bold font-condensed text-base xl:text-xl mb-2">{{ service.title }}</h3>
                <p class="font-condensed font-light text-sm xl:text-base text leading-relaxed">{{ service.description }}</p>
            </div>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: 'service',
        props: {
            name: {
                type: String,
            },
        },
        computed: {
            service () {
                switch (this.name) {
                    case 'cd-artwork':
                        return {
                            title: 'CD Artwork',
                            description: 'Whether an exciting new idea or a reimagination of something great, we can help you stand out from the crowd',
                            path: '/branding',
                        }
                    case 'branding':
                        return {
                            title: 'Branding',
                            description: 'Whether it\'s an exciting new idea or a reimagination of something great, we can help you stand out from the crowd',
                            path: '',
                        }
                    case 'design':
                        return {
                            title: 'Design',
                            description: 'Steal the show with expertly crafted and eye catching designs. Whatever the medium, you\'ll always turn heads',
                            path: '/design',
                        }
                    case 'printing':
                        return {
                            title: 'Printing',
                            description:'Hold your idea in your hands. From business cards, invites and stationery to exhibition displays, we print it all',
                            path: '/printing',
                        }
                    case 'stationery':
                        return {
                            title: 'Stationery',
                            description:'From business cards and letterheads to signs and banners',
                            path: '',
                        }
                    case 'brochure':
                        return {
                            title: 'Brochure',
                            description: 'Something to download and remember us by',
                            path: '/brochure',
                        }
                    default: 
                        throw new Error('Invalid service name.')
                }
            },
            style () {
                return this.name != 'brochure' ? 'service-service' : 'service-brochure'
            }
        }
    }
</script>

<style scoped>
    .service {
        cursor: pointer;
    }
    .service-service {
        @apply bg-gray-900
    }
    .service-service:hover {
        @apply bg-black
    }
    .service-service h3 {
        @apply text-gray-400
    }
    .service-service p {
        @apply text-gray-500
    }
    .service-brochure {
        @apply bg-red-700
    }
    .service-brochure:hover {
        @apply bg-red-800
    }
    .service-brochure h3 {
        @apply text-red-200
    }
    .service-brochure p {
        @apply text-red-300
    }
</style>