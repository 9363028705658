<template>
	<div id="body" class="w-full">
		<Header/>
		<main>
			<Hero/>
			<Services/>
			<Showcase/>
			<Testimonials/>
			<Quote/>
			<Footer/>
		</main>
	</div>
</template>

<script>
	import Header from '@/components/Header'
	import Hero from '@/components/Hero'
	import Services from '@/components/Services'
	import Showcase from '@/components/Showcase'
	import Testimonials from '@/components/testimonials/Testimonials'
	import Quote from '@/components/cta/Quote'
	import Footer from '@/components/Footer'

	export default {
		name: 'Home',
		components: {
			Header,
			Hero,
			Services,
			Showcase,
			Testimonials,
			Quote,
			Footer,
		},
	}
</script>
