<template>
	<div class="pb-12 lg:pb-0 what transition-all" :class="background">
		<div class="container mx-auto flex flex-col flex-col-reverse lg:flex-row relative z-20">
			<div class="lg:w-1/2 lg:mr-3 flex justify-center">
				<div class="services px-6 lg:px-0 flex flex-wrap justify-between">
					<div class="sm:w-1/2 lg:-mt-12">
						<Service name="branding" @mouseover.native="mouseOver('branding')" @mouseleave.native="mouseLeave"/>
						<Service name="printing" @mouseover.native="mouseOver('print')" @mouseleave.native="mouseLeave"/>
					</div>
					<div class="sm:w-1/2 lg:-mb-12 lg:mt-12">
						<Service name="design" @mouseover.native="mouseOver('design')" @mouseleave.native="mouseLeave"/>
						<Service name="brochure"/>
					</div>
				</div>
			</div>
			<div class="lg:w-1/2 lg:ml-3 px-6 pt-12 pb-6 lg:pt-12 lg:pb-12">
				<h2 class="uppercase text-red-700 font-bold text-xs font-heading">The what</h2>
				<h3 class="text-gray-300 text-3xl font-heading font-bold mb-5">What we do best</h3>
				<p class="text-sm md:text-base font-condesend font-body text-gray-400 leading-relaxed mb-4">Here at iNQUiSiTiON we pride ourselves on being your one stop shop for all things digital design. We're passionate about exceptional design that speaks the language of you and your brand.</p>

				<p class="text-sm md:text-base font-light font-body text-gray-400 leading-relaxed">We can help you come up with that cutting edge new brand you've been dreaming of. Need a stunning design for your next exhibition, promotional material or product? Or maybe you want to bring your idea to life with our printing services. Wherever you are in your digital journey, we can help.</p>
			</div>
		</div>
	</div>
</template>

<script>
	import Service from '@/components/services/Service.vue'
	
	export default {
		name: 'services',
		components: {
			Service
		},
		data () {
			return {
				background: ''
			}
		},
		methods: {
			mouseOver: function (service) {
				return this.background = service
			},
			mouseLeave: function () {
				return this.background = ''
			}
		}
	}
</script>

<style scoped>
	.what {
		position: relative;
	}

	.what:before {
		background-image: url(~@/assets/img/what-branding.jpg);
		background-position: top center;
		background-repeat: no-repeat;
		background-size: cover;
		content: '';
		display: block;
		height: inherit;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		width: inherit;
		-webkit-transition: background-image 1s ease-in-out;
		transition: background-image 1s ease-in-out;
		z-index: 0;
	}
	.what.branding:before {
		background-image: url(~@/assets/img/what-branding.jpg);
	}
	.what.design:before {
		background-image: url(~@/assets/img/what-design.jpg);
	}
	.what.print:before {
		background-image: url(~@/assets/img/what-print.jpg);
	}

	.what:after {
		@apply bg-gray-900;
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		opacity: .875;
		-webkit-transition: opacity 1s ease-in-out;
		transition: opacity 1s ease-in-out;
		z-index: 5;
	}
	.what.branding:after,
	.what.design:after,
	.what.print:after {
		opacity: .75;
	}
</style>